
import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/WebDevelopmentService.css";
import WebDesign from "../assets/web-development-design-process.png";
import { useNavigate } from "react-router-dom";
import WebDesignMobile from "../assets/web-development-design-process-mobile.png";
import LatestWorksPage from "../components/LatestWorks";
import LetsGetStarted from "../components/LetsGetStarted";

const WebDevelopmentService = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleButtonClick = () => {
    navigate("/contact-us");
  };

  return (
    <div className="container-web-development">
      <div className="web-development-navbar navbar-service">
        <Navbar></Navbar>
      </div>

      <div className="container-web-development-content">
        <div className="web-development-header">
          <div className="web-development-header-box">
            <div className="web-development-header-title-box">
              Web Development Service
            </div>
            <div className="web-development-header-title">
              <p>Elevate Your Online Presence</p>
              <p>with Expert Web Development</p>
            </div>
            <div className="web-development-header-desc">
              Welcome to Lakuuu Digital, where we transform your digital
              aspirations into stunning and functional web solutions. Our web
              development services are designed to enhance user experience,
              drive engagement, and elevate your brand in the online landscape.
            </div>
            <button
              className="web-development-header-btn"
              onClick={handleButtonClick}
            >
              Get in Touch
            </button>
          </div>
        </div>
      </div>

      <div className="container-web-development-services">
        {isMobile ? (
          <div className="web-development-services-section-1">
            <div className="web-development-services-section-1-title">
              <p>Why Choose Our</p>
              <p className="orange-text-web-development">Web Development</p>
              <p>Service?</p>
            </div>

            <div className="web-development-services-section-1-card">
              <div className="web-development-services-section-1-card-title">
                <div className="web-development-services-section-1-card-title-number">
                  1
                </div>
                <div className="web-development-services-section-1-card-title-name">
                  Strategic Approach
                </div>
              </div>
              <div className="web-development-services-section-1-card-desc">
                <ul>
                  <li>
                    We take a holistic view of your online objectives, tailoring
                    our web development strategy to align with your business
                    goals.
                  </li>
                  <li>
                    Strategic planning for a website that not only looks great
                    but also performs seamlessly.
                  </li>
                </ul>
              </div>
            </div>

            <div className="web-development-services-section-1-card">
              <div className="web-development-services-section-1-card-title">
                <div className="web-development-services-section-1-card-title-number">
                  2
                </div>
                <div className="web-development-services-section-1-card-title-name">
                  Responsive Design
                </div>
              </div>
              <div className="web-development-services-section-1-card-desc">
                <ul>
                  <li>
                    Our websites are crafted with responsive design, ensuring
                    optimal viewing experiences across various devices and
                    screen sizes.
                  </li>
                  <li>
                    Mobile-friendly solutions that cater to the evolving needs
                    of your audience.
                  </li>
                </ul>
              </div>
            </div>

            <div className="web-development-services-section-1-card">
              <div className="web-development-services-section-1-card-title">
                <div className="web-development-services-section-1-card-title-number">
                  3
                </div>
                <div className="web-development-services-section-1-card-title-name">
                  User-Centric Development
                </div>
              </div>
              <div className="web-development-services-section-1-card-desc">
                <ul>
                  <li>
                    User experience is at the forefront of our development
                    process, creating intuitive interfaces that captivate and
                    engage visitors.
                  </li>
                  <li>
                    Seamless navigation and user-friendly features for enhanced
                    satisfaction.
                  </li>
                </ul>
              </div>
            </div>

            <div className="web-development-services-section-1-card">
              <div className="web-development-services-section-1-card-title">
                <div className="web-development-services-section-1-card-title-number">
                  4
                </div>
                <div className="web-development-services-section-1-card-title-name">
                  Scalability and Performance
                </div>
              </div>
              <div className="web-development-services-section-1-card-desc">
                <ul>
                  <li>
                    We build scalable websites that grow with your business,
                    ensuring optimal performance even as your online presence
                    expands.
                  </li>
                  <li>
                    Focus on speed and efficiency to provide a smooth browsing
                    experience for your audience.
                  </li>
                </ul>
              </div>
            </div>

            <div className="web-development-services-section-1-card">
              <div className="web-development-services-section-1-card-title">
                <div className="web-development-services-section-1-card-title-number">
                  5
                </div>
                <div className="web-development-services-section-1-card-title-name">
                  Content Management Systems (CMS)
                </div>
              </div>
              <div className="web-development-services-section-1-card-desc">
                <ul>
                  <li>
                    Easy-to-use content management systems empower you to update
                    and manage your website content effortlessly.
                  </li>
                  <li>
                    We offer solutions that put you in control of your online
                    presence.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="web-development-services-section-1">
              <div className="web-development-services-section-1-title">
                <p>Why Choose Our</p>
                <p className="orange-text-web-development">Web Development</p>
                <p>Services?</p>
              </div>
              <div className="web-development-services-section-1-card">
                <div className="web-development-services-section-1-card-title">
                  <div className="web-development-services-section-1-card-title-number">
                    2
                  </div>
                  <div className="web-development-services-section-1-card-title-name">
                    Responsive Design
                  </div>
                </div>
                <div className="web-development-services-section-1-card-desc">
                  <ul>
                    <li>
                      Our websites are crafted with responsive design, ensuring
                      optimal viewing experiences across various devices and
                      screen sizes.
                    </li>
                    <li>
                      Mobile-friendly solutions that cater to the evolving needs
                      of your audience.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="web-development-services-section-1-card">
                <div className="web-development-services-section-1-card-title">
                  <div className="web-development-services-section-1-card-title-number">
                    4
                  </div>
                  <div className="web-development-services-section-1-card-title-name">
                    Scalability and Performance
                  </div>
                </div>
                <div className="web-development-services-section-1-card-desc">
                  <ul>
                    <li>
                      We build scalable websites that grow with your business,
                      ensuring optimal performance even as your online presence
                      expands.
                    </li>
                    <li>
                      Focus on speed and efficiency to provide a smooth browsing
                      experience for your audience.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="web-development-services-section-2">
              <div className="web-development-services-section-2-card top-card">
                <div className="web-development-services-section-2-card-title">
                  <div className="web-development-services-section-2-card-title-number">
                    1
                  </div>
                  <div className="web-development-services-section-2-card-title-name">
                    Strategic Approach
                  </div>
                </div>
                <div className="web-development-services-section-2-card-desc">
                  <ul>
                    <li>
                      We take a holistic view of your online objectives,
                      tailoring our web development strategy to align with your
                      business goals.
                    </li>
                    <li>
                      Strategic planning for a website that not only looks great
                      but also performs seamlessly.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="web-development-services-section-2-card">
                <div className="web-development-services-section-2-card-title">
                  <div className="web-development-services-section-2-card-title-number">
                    3
                  </div>
                  <div className="web-development-services-section-2-card-title-name">
                    User-Centric Development
                  </div>
                </div>
                <div className="web-development-services-section-2-card-desc">
                  <ul>
                    <li>
                      User experience is at the forefront of our development
                      process, creating intuitive interfaces that captivate and
                      engage visitors.
                    </li>
                    <li>
                      Seamless navigation and user-friendly features for
                      enhanced satisfaction.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="web-development-services-section-2-card">
                <div className="web-development-services-section-2-card-title">
                  <div className="web-development-services-section-2-card-title-number">
                    5
                  </div>
                  <div className="web-development-services-section-2-card-title-name">
                    Content Management Systems (CMS)
                  </div>
                </div>
                <div className="web-development-services-section-2-card-desc">
                  <ul>
                    <li>
                      Easy-to-use content management systems empower you to
                      update and manage your website content effortlessly.
                    </li>
                    <li>
                      We offer solutions that put you in control of your online
                      presence.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="container-web-development-design-process">
        <img
          src={isMobile ? WebDesignMobile : WebDesign}
          alt="web-development-design"
        />
      </div>

      <div className="container-latest-work">
        <div className="work-card">
          <LatestWorksPage></LatestWorksPage>
        </div>
      </div>

      <div className="home-lets-get-started">
        <LetsGetStarted></LetsGetStarted>
      </div>

      <div className="web-development-footer mt-20 pb-20 footer-service">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default WebDevelopmentService;
