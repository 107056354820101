
import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/UiUx.css";
import UiUxDesign from "../assets/ui-ux-design-process.png";
import { useNavigate } from 'react-router-dom';
import UiUxDesignMobile from "../assets/ui-ux-design-process-mobile.png";
import LatestWorksPage from "../components/LatestWorks";
import LetsGetStarted from "../components/LetsGetStarted";

const UiUxDesignService = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleButtonClick = () => {
    navigate('/contact-us');
  };

  return (
    <div className="container-ui-ux">
      <div className="ui-ux-navbar navbar-service">
        <Navbar></Navbar>
      </div>

      <div className="container-ui-ux-content">
        <div className="ui-ux-header">
          <div className="ui-ux-header-box">
            <div className="ui-ux-header-title-box">UI UX Design Service</div>
            <div className="ui-ux-header-title">
              <p>Designing Seamless</p>
              <p>Experiences for Your Digital World</p>
            </div>
            <div className="ui-ux-header-desc">
              Welcome to Lakuuu Digital, where we specialize in crafting
              user-friendly and visually appealing digital interfaces. Our UI/UX
              design services are tailored to enhance user satisfaction and make
              your digital presence stand out.
            </div>
            <button className="ui-ux-header-btn" onClick={handleButtonClick}>Get in Touch</button>
          </div>
        </div>
      </div>

      <div className="container-ui-ux-services">
        {isMobile ? (
          <div className="ui-ux-services-section-1">
            <div className="ui-ux-services-section-1-title">
              <p>Why Choose Our</p>
              <p className="orange-text-ui-ux">UI UX Design</p>
              <p>Services?</p>
            </div>

            <div className="ui-ux-services-section-1-card">
              <div className="ui-ux-services-section-1-card-title">
                <div className="ui-ux-services-section-1-card-title-number">
                  1
                </div>
                <div className="ui-ux-services-section-1-card-title-name">
                  User-Centric Approach
                </div>
              </div>
              <div className="ui-ux-services-section-1-card-desc">
                <ul>
                  <li>
                    Deep understanding of your target audience to design
                    interfaces that resonate with users.
                  </li>
                  <li>
                    Crafting experiences that go beyond aesthetics, focusing on
                    user needs and preferences.
                  </li>
                </ul>
              </div>
            </div>

            <div className="ui-ux-services-section-1-card">
              <div className="ui-ux-services-section-1-card-title">
                <div className="ui-ux-services-section-1-card-title-number">
                  2
                </div>
                <div className="ui-ux-services-section-1-card-title-name">
                  Creative and Intuitive Designs
                </div>
              </div>
              <div className="ui-ux-services-section-1-card-desc">
                <ul>
                  <li>
                    Innovative and visually appealing designs that not only
                    capture attention but enhance usability.
                  </li>
                  <li>
                    Intuitive navigation and interaction patterns for a seamless
                    user journey.
                  </li>
                </ul>
              </div>
            </div>

            <div className="ui-ux-services-section-1-card">
              <div className="ui-ux-services-section-1-card-title">
                <div className="ui-ux-services-section-1-card-title-number">
                  3
                </div>
                <div className="ui-ux-services-section-1-card-title-name">
                  Responsive Design for All Devices
                </div>
              </div>
              <div className="ui-ux-services-section-1-card-desc">
                <ul>
                  <li>
                    Designs optimized for various devices and screen sizes,
                    ensuring consistency across platforms.
                  </li>
                  <li>
                    Mobile-friendly and responsive interfaces that adapt to the
                    evolving digital landscape.
                  </li>
                </ul>
              </div>
            </div>

            <div className="ui-ux-services-section-1-card">
              <div className="ui-ux-services-section-1-card-title">
                <div className="ui-ux-services-section-1-card-title-number">
                  4
                </div>
                <div className="ui-ux-services-section-1-card-title-name">
                  Brand Alignment
                </div>
              </div>
              <div className="ui-ux-services-section-1-card-desc">
                <ul>
                  <li>
                    Aligning UI/UX designs with your brand identity to create a
                    cohesive and memorable user experience.
                  </li>
                  <li>
                    Consistent use of colors, fonts, and elements that reflect
                    your brand's personality.
                  </li>
                </ul>
              </div>
            </div>

            <div className="ui-ux-services-section-1-card">
              <div className="ui-ux-services-section-1-card-title">
                <div className="ui-ux-services-section-1-card-title-number">
                  5
                </div>
                <div className="ui-ux-services-section-1-card-title-name">
                  Usability Testing
                </div>
              </div>
              <div className="ui-ux-services-section-1-card-desc">
                <ul>
                  <li>
                    Conducting usability testing to validate design choices and
                    refine the user interface.
                  </li>
                  <li>
                    Iterative design processes to ensure continuous improvement
                    based on user feedback.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="ui-ux-services-section-1">
              <div className="ui-ux-services-section-1-title">
                <p>Why Choose Our</p>
                <p className="orange-text-ui-ux">UI UX Design</p>
                <p>Services?</p>
              </div>
              <div className="ui-ux-services-section-1-card">
                <div className="ui-ux-services-section-1-card-title">
                  <div className="ui-ux-services-section-1-card-title-number">
                    2
                  </div>
                  <div className="ui-ux-services-section-1-card-title-name">
                    Creative and Intuitive Designs
                  </div>
                </div>
                <div className="ui-ux-services-section-1-card-desc">
                  <ul>
                    <li>
                      Innovative and visually appealing designs that not only
                      capture attention but enhance usability.
                    </li>
                    <li>
                      Intuitive navigation and interaction patterns for a
                      seamless user journey.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ui-ux-services-section-1-card">
                <div className="ui-ux-services-section-1-card-title">
                  <div className="ui-ux-services-section-1-card-title-number">
                    4
                  </div>
                  <div className="ui-ux-services-section-1-card-title-name">
                    Brand Alignment
                  </div>
                </div>
                <div className="ui-ux-services-section-1-card-desc">
                  <ul>
                    <li>
                      Aligning UI/UX designs with your brand identity to create
                      a cohesive and memorable user experience.
                    </li>
                    <li>
                      Consistent use of colors, fonts, and elements that reflect
                      your brand's personality.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="ui-ux-services-section-2">
              <div className="ui-ux-services-section-2-card top-card">
                <div className="ui-ux-services-section-2-card-title">
                  <div className="ui-ux-services-section-2-card-title-number">
                    1
                  </div>
                  <div className="ui-ux-services-section-2-card-title-name">
                    User-Centric Approach
                  </div>
                </div>
                <div className="ui-ux-services-section-2-card-desc">
                  <ul>
                    <li>
                      Deep understanding of your target audience to design
                      interfaces that resonate with users.
                    </li>
                    <li>
                      Crafting experiences that go beyond aesthetics, focusing
                      on user needs and preferences.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ui-ux-services-section-2-card">
                <div className="ui-ux-services-section-2-card-title">
                  <div className="ui-ux-services-section-2-card-title-number">
                    3
                  </div>
                  <div className="ui-ux-services-section-2-card-title-name">
                    Responsive Design for All Devices
                  </div>
                </div>
                <div className="ui-ux-services-section-2-card-desc">
                  <ul>
                    <li>
                      Designs optimized for various devices and screen sizes,
                      ensuring consistency across platforms.
                    </li>
                    <li>
                      Mobile-friendly and responsive interfaces that adapt to
                      the evolving digital landscape.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ui-ux-services-section-2-card">
                <div className="ui-ux-services-section-2-card-title">
                  <div className="ui-ux-services-section-2-card-title-number">
                    5
                  </div>
                  <div className="ui-ux-services-section-2-card-title-name">
                    Usability Testing
                  </div>
                </div>
                <div className="ui-ux-services-section-2-card-desc">
                  <ul>
                    <li>
                      Conducting usability testing to validate design choices
                      and refine the user interface.
                    </li>
                    <li>
                      Iterative design processes to ensure continuous
                      improvement based on user feedback.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="container-ui-ux-design-process">
        <img
          src={isMobile ? UiUxDesignMobile : UiUxDesign}
          alt="ui-ux-design"
        />
      </div>

      <div className="container-latest-work">
        <div className="work-card">
          <LatestWorksPage></LatestWorksPage>
        </div>
      </div>

      <div className="home-lets-get-started">
            <LetsGetStarted></LetsGetStarted>
      </div>

      <div className="ui-ux-footer mt-20 pb-20 footer-service">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default UiUxDesignService;
