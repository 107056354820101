import { useNavigate } from 'react-router-dom';
import '../styles/LetsGetStarted.css';

const LetsGetStarted = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/contact-us');
  };

  return (
    <div className='container-get-started'>
      <div className='title'>
        Let's Get Started
      </div>

      <div className='content'>
        <div className='content-title'>
            <p>Ready to enhance your digital experience?</p>
            <p>Reach out to us today</p>
        </div>

        <button className='content-btn' onClick={handleButtonClick}>
            Get in Touch
        </button>
      </div>
    </div>
  );
};

export default LetsGetStarted;
