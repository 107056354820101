import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import '../styles/navbar.css';
import logo from '../assets/logo.png'; 

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLLIElement>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleGetInTouchClick = () => {
    navigate("/contact-us");
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <div className="flex justify-center py-6">
        <div className="navbar bg-white rounded-full h-20 relative border border-[#EDF1F5] shadow-xl">
          <div className="flex-1 lg:flex-none flex items-center pl-4">
            <button onClick={handleLogoClick}>
              <img src={logo} alt="Logo" className="logo-image" />
            </button>
          </div>
          <div className="navbar-center hidden lg:flex flex-grow"></div>
          <div className="navbar-end flex items-center space-x-4">
            <div className="dropdown lg:hidden relative">
              <button className="btn btn-ghost" onClick={toggleMenu}>
                {isMenuOpen ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4h16M4 12h16M4 20h16" />
                  </svg>
                )}
              </button>
            </div>
            <div className="hidden lg:flex items-center">
              <ul className="menu menu-horizontal px-1">
                <li>
                  <a href="/our-works" className="text-custom-gray text-base font-poppins">Our Works</a>
                </li>
                <li className="relative" ref={dropdownRef}>
                  <button onClick={toggleDropdown} className="text-custom-gray text-base font-poppins flex items-center">
                    Services
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  {isDropdownOpen && (
                    <ul className="p-2 bg-white mt-12 rounded-lg shadow-lg card-border card-shadow absolute left-1/2 transform -translate-x-1/2 z-10">
                      {[
                        { label: "UI UX Design", href: "/ui-ux" },
                        { label: "Custom Software Development", href: "/custom-software-development" },
                        { label: "Web Development", href: "/development-service" },
                        { label: "Mobile App Development", href: "/mobile-app-development-service" },
                        { label: "Software Integration and Migration", href: "/software-integration-service" },
                        { label: "E-commerce Solutions", href: "/ecommerce-solution" },
                        { label: "Quality Assurance and Testing", href: "/quality-assurance" },
                      ].map(({ label, href }) => (
                        <li key={label}>
                          <a href={href} className="text-custom-gray text-base font-poppins whitespace-nowrap">
                            {label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              </ul>
              <button className="custom-btn ml-4" onClick={handleGetInTouchClick}>Get in Touch</button>
            </div>
          </div>
        </div>
      </div>
      <div className={`mobile-menu-card ${isMenuOpen ? "block" : "hidden"}`}>
        <div className="mobile-menu-content">
          <a href="/our-works" className="text-custom-gray text-base font-poppins">Our Works</a>
          <div className="separator"></div>
          <details className="relative">
            <summary className="text-custom-gray text-base font-poppins flex items-center">
              Services
              <svg xmlns="http://www.w3.org/2000/svg" className="inline h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </summary>
            <ul className="p-2 md:bg-white mt-2 md:rounded-lg md:shadow-lg">
              {[
                { label: "UI UX Design", href: "/ui-ux" },
                { label: "Custom Software Development", href: "/custom-software-development" },
                { label: "Web Development", href: "/development-service" },
                { label: "Mobile App Development", href: "/mobile-app-development-service" },
                { label: "Software Integration and Migration", href: "/software-integration-service" },
                { label: "E-commerce Solutions", href: "/ecommerce-solution" },
                { label: "Quality Assurance and Testing", href: "/quality-assurance" },
              ].map(({ label, href }) => (
                <li key={label}>
                  <a href={href} className="text-custom-gray text-base font-poppins whitespace-nowrap block">
                    {label}
                  </a>
                </li>
              ))}
            </ul>
          </details>
          <div className="separator"></div>
          <button className="custom-btn-mobile mt-4" onClick={handleGetInTouchClick}>Get in Touch</button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;