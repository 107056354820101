
import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/CustomSoftwareDevelopment.css";
import UiUxDesign from "../assets/software-development-design-process.png";
import { useNavigate } from 'react-router-dom';
import UiUxDesignMobile from "../assets/software-development-design-process-mobile.png";
import LatestWorksPage from "../components/LatestWorks";
import LetsGetStarted from "../components/LetsGetStarted";

const CustomSoftwareDevelopment = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleButtonClick = () => {
    navigate('/contact-us');
  };

  return (
    <div className="container-software-development">
      <div className="software-development-navbar navbar-service">
        <Navbar></Navbar>
      </div>

      <div className="container-software-development-content">
        <div className="software-development-header">
          <div className="software-development-header-box">
            <div className="software-development-header-title-box">Custom Software Development Service</div>
            <div className="software-development-header-title">
              <p>Tailored Solutions for</p>
              <p> Your Unique Needs</p>
            </div>
            <div className="software-development-header-desc">
            Welcome to Lakuuu Digital, your dedicated partner in unleashing the power of custom software solutions. Our expert team is committed to translating your vision into reality through precision coding and innovative development practices.
            </div>
            <button className="software-development-header-btn" onClick={handleButtonClick}>Get in Touch</button>
          </div>
        </div>
      </div>

      <div className="container-software-development-services">
        {isMobile ? (
          <div className="software-development-services-section-1">
            <div className="software-development-services-section-1-title">
                <p>
                    Why Choose Our 
                    </p>

                    <p className="orange-text-software-development">
                    Custom Software
                    </p>

                    <p className="orange-text-software-development">
                    Development
                    </p>

                    <p>Service?</p>
            </div>

            <div className="software-development-services-section-1-card">
              <div className="software-development-services-section-1-card-title">
                <div className="software-development-services-section-1-card-title-number">
                  1
                </div>
                <div className="software-development-services-section-1-card-title-name">
                  User-Centric Approach
                </div>
              </div>
              <div className="software-development-services-section-1-card-desc">
                <ul>
                  <li>
                    Deep understanding of your target audience to design
                    interfaces that resonate with users.
                  </li>
                  <li>
                    Crafting experiences that go beyond aesthetics, focusing on
                    user needs and preferences.
                  </li>
                </ul>
              </div>
            </div>

            <div className="software-development-services-section-1-card">
              <div className="software-development-services-section-1-card-title">
                <div className="software-development-services-section-1-card-title-number">
                  2
                </div>
                <div className="software-development-services-section-1-card-title-name">
                  Creative and Intuitive Designs
                </div>
              </div>
              <div className="software-development-services-section-1-card-desc">
                <ul>
                  <li>
                    Innovative and visually appealing designs that not only
                    capture attention but enhance usability.
                  </li>
                  <li>
                    Intuitive navigation and interaction patterns for a seamless
                    user journey.
                  </li>
                </ul>
              </div>
            </div>

            <div className="software-development-services-section-1-card">
              <div className="software-development-services-section-1-card-title">
                <div className="software-development-services-section-1-card-title-number">
                  3
                </div>
                <div className="software-development-services-section-1-card-title-name">
                  Responsive Design for All Devices
                </div>
              </div>
              <div className="software-development-services-section-1-card-desc">
                <ul>
                  <li>
                    Designs optimized for various devices and screen sizes,
                    ensuring consistency across platforms.
                  </li>
                  <li>
                    Mobile-friendly and responsive interfaces that adapt to the
                    evolving digital landscape.
                  </li>
                </ul>
              </div>
            </div>

            <div className="software-development-services-section-1-card">
              <div className="software-development-services-section-1-card-title">
                <div className="software-development-services-section-1-card-title-number">
                  4
                </div>
                <div className="software-development-services-section-1-card-title-name">
                  Brand Alignment
                </div>
              </div>
              <div className="software-development-services-section-1-card-desc">
                <ul>
                  <li>
                    Aligning UI/UX designs with your brand identity to create a
                    cohesive and memorable user experience.
                  </li>
                  <li>
                    Consistent use of colors, fonts, and elements that reflect
                    your brand's personality.
                  </li>
                </ul>
              </div>
            </div>

            <div className="software-development-services-section-1-card">
              <div className="software-development-services-section-1-card-title">
                <div className="software-development-services-section-1-card-title-number">
                  5
                </div>
                <div className="software-development-services-section-1-card-title-name">
                  Usability Testing
                </div>
              </div>
              <div className="software-development-services-section-1-card-desc">
                <ul>
                  <li>
                    Conducting usability testing to validate design choices and
                    refine the user interface.
                  </li>
                  <li>
                    Iterative design processes to ensure continuous improvement
                    based on user feedback.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="software-development-services-section-1">
              <div className="software-development-services-section-1-title">
                <div>Why Choose Our 
                  <span className="orange-text-software-development"> Custom Software Development </span>
                  Service?
                </div>
              </div>
              <div className="software-development-services-section-1-card-list">
                <div className="software-development-services-section-1-card">
                  <div className="software-development-services-section-1-card-title">
                    <div className="software-development-services-section-1-card-title-number">
                      1
                    </div>
                    <div className="software-development-services-section-1-card-title-name">
                    Personalized Excellence
                    </div>
                  </div>
                  <div className="software-development-services-section-1-card-desc">
                    <ul>
                      <li>
                      Every line of code is meticulously crafted to align with your specific business requirements
                      </li>
                      <li>
                      Tailored solutions that cater to your unique needs, ensuring a perfect fit.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="software-development-services-section-1-card">
                  <div className="software-development-services-section-1-card-title">
                    <div className="software-development-services-section-1-card-title-number">
                      2
                    </div>
                    <div className="software-development-services-section-1-card-title-name">
                    Seamless Integration
                    </div>
                  </div>
                  <div className="software-development-services-section-1-card-desc">
                    <ul>
                      <li>
                      Our software seamlessly integrates with your existing systems, optimizing processes and workflow efficiency.
                      </li>
                      <li>
                      Effortless incorporation into your business environment for a smooth transition.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="software-development-services-section-1-card">
                <div className="software-development-services-section-1-card-title">
                  <div className="software-development-services-section-1-card-title-number">
                    3
                  </div>
                  <div className="software-development-services-section-1-card-title-name">
                  Security First Approach
                  </div>
                </div>
                <div className="software-development-services-section-1-card-desc">
                  <ul>
                    <li>
                    Robust security measures are embedded in our development process to safeguard your valuable data.
                    </li>
                    <li>
                    Trust us to prioritize the confidentiality and integrity of your information.
                    </li>
                  </ul>
                </div>
                </div>

                <div className="software-development-services-section-1-card">
                  <div className="software-development-services-section-1-card-title">
                    <div className="software-development-services-section-1-card-title-number">
                      4
                    </div>
                    <div className="software-development-services-section-1-card-title-name">
                    Agile Development Methodology
                    </div>
                  </div>
                  <div className="software-development-services-section-1-card-desc">
                    <ul>
                      <li>
                      Stay ahead of the curve with our agile development approach, allowing for flexibility and rapid adaptations.
                      </li>
                      <li>
                      Quick iterations and continuous feedback loops for a responsive and evolving software solution.
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </>
        )}
      </div>

      <div className="container-software-development-design-process">
        <img
          src={isMobile ? UiUxDesignMobile : UiUxDesign}
          alt="software-development-design"
        />
      </div>

      <div className="container-latest-work">
        <div className="work-card">
          <LatestWorksPage></LatestWorksPage>
        </div>
      </div>

      <div className="home-lets-get-started">
            <LetsGetStarted></LetsGetStarted>
      </div>

      <div className="software-development-footer mt-20 pb-20 footer-service">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default CustomSoftwareDevelopment;
