import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/SoftwareIntegrationService.css";
import WebDesign from "../assets/software-integration-design-process.png";
import { useNavigate } from "react-router-dom";
import WebDesignMobile from "../assets/software-integration-design-process-mobile.png";
import LatestWorksPage from "../components/LatestWorks";
import LetsGetStarted from "../components/LetsGetStarted";

const SoftwareIntegrationService = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleButtonClick = () => {
    navigate("/contact-us");
  };

  return (
    <div className="container-software-integration">
      <div className="software-integration-navbar navbar-service">
        <Navbar></Navbar>
      </div>

      <div className="container-software-integration-content">
        <div className="software-integration-header">
          <div className="software-integration-header-box">
            <div className="software-integration-header-title-box">
              Software Integration & Migration Service
            </div>
            <div className="software-integration-header-title">
              <p>Seamless Transitions,</p>
              <p>Elevated Performance</p>
            </div>
            <div className="software-integration-header-desc">
              Welcome to Lakuuu Digital, your partner in streamlining operations
              through expert software integration and migration services.
              Whether you're upgrading systems or connecting applications, we
              specialize in delivering smooth transitions and enhanced
              performance.{" "}
            </div>
            <button
              className="software-integration-header-btn"
              onClick={handleButtonClick}
            >
              Get in Touch
            </button>
          </div>
        </div>
      </div>

      <div className="container-software-integration-services">
        {isMobile ? (
          <div className="software-integration-services-section-1">
            <div className="software-integration-services-section-1-title">
              <p>Why Choose Our</p>
              <p className="orange-text-software-integration">
                Integration and
              </p>
              <p className="orange-text-software-integration">Migration</p>
              <p>Service?</p>
            </div>

            <div className="software-integration-services-section-1-card">
              <div className="software-integration-services-section-1-card-title">
                <div className="software-integration-services-section-1-card-title-number">
                  1
                </div>
                <div className="software-integration-services-section-1-card-title-name">
                  Tailored Integration Solutions
                </div>
              </div>
              <div className="software-integration-services-section-1-card-desc">
                <ul>
                  <li>
                    Custom integration solutions designed to connect disparate
                    systems, optimizing workflow efficiency.{" "}
                  </li>
                  <li>
                    Tailor-made strategies to seamlessly integrate new software
                    into your existing infrastructure.{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div className="software-integration-services-section-1-card">
              <div className="software-integration-services-section-1-card-title">
                <div className="software-integration-services-section-1-card-title-number">
                  2
                </div>
                <div className="software-integration-services-section-1-card-title-name">
                  Data Migration Expertise{" "}
                </div>
              </div>
              <div className="software-integration-services-section-1-card-desc">
                <ul>
                  <li>
                    Smooth and secure data migration processes to ensure a
                    hassle-free transition to new platforms.{" "}
                  </li>
                  <li>
                    Expert handling of data, minimizing downtime and potential
                    disruptions.{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div className="software-integration-services-section-1-card">
              <div className="software-integration-services-section-1-card-title">
                <div className="software-integration-services-section-1-card-title-number">
                  3
                </div>
                <div className="software-integration-services-section-1-card-title-name">
                  Legacy System Upgrades{" "}
                </div>
              </div>
              <div className="software-integration-services-section-1-card-desc">
                <ul>
                  <li>
                    Upgrading legacy systems to modern technologies for improved
                    functionality and performance.{" "}
                  </li>
                  <li>
                    Future-proofing your business by keeping your software
                    infrastructure up-to-date.{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div className="software-integration-services-section-1-card">
              <div className="software-integration-services-section-1-card-title">
                <div className="software-integration-services-section-1-card-title-number">
                  4
                </div>
                <div className="software-integration-services-section-1-card-title-name">
                  Interoperability Assurance{" "}
                </div>
              </div>
              <div className="software-integration-services-section-1-card-desc">
                <ul>
                  <li>
                    Ensuring your systems work seamlessly together, promoting
                    interoperability and collaboration.{" "}
                  </li>
                  <li>
                    Integration solutions that enhance communication and data
                    flow across your organization.{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div className="software-integration-services-section-1-card">
              <div className="software-integration-services-section-1-card-title">
                <div className="software-integration-services-section-1-card-title-number">
                  5
                </div>
                <div className="software-integration-services-section-1-card-title-name">
                  Security-First Approach{" "}
                </div>
              </div>
              <div className="software-integration-services-section-1-card-desc">
                <ul>
                  <li>
                    Prioritizing data security throughout the integration and
                    migration processes.{" "}
                  </li>
                  <li>
                    Implementing robust security measures to protect sensitive
                    information during transitions.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="software-integration-services-section-1">
              <div className="software-integration-services-section-1-title">
                <p>Why Choose Our</p>
                <p className="orange-text-software-integration">
                  Integration and
                </p>
                <p className="orange-text-software-integration">Migration</p>
                <p>Service?</p>
              </div>

              <div className="software-integration-services-section-1-card">
                <div className="software-integration-services-section-1-card-title">
                  <div className="software-integration-services-section-1-card-title-number">
                    2
                  </div>
                  <div className="software-integration-services-section-1-card-title-name">
                    Data Migration Expertise{" "}
                  </div>
                </div>
                <div className="software-integration-services-section-1-card-desc">
                  <ul>
                    <li>
                      Smooth and secure data migration processes to ensure a
                      hassle-free transition to new platforms.{" "}
                    </li>
                    <li>
                      Expert handling of data, minimizing downtime and potential
                      disruptions.{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="software-integration-services-section-1-card">
                <div className="software-integration-services-section-1-card-title">
                  <div className="software-integration-services-section-1-card-title-number">
                    4
                  </div>
                  <div className="software-integration-services-section-1-card-title-name">
                    Interoperability Assurance{" "}
                  </div>
                </div>
                <div className="software-integration-services-section-1-card-desc">
                  <ul>
                    <li>
                      Ensuring your systems work seamlessly together, promoting
                      interoperability and collaboration.{" "}
                    </li>
                    <li>
                      Integration solutions that enhance communication and data
                      flow across your organization.{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="software-integration-services-section-2">
              <div className="software-integration-services-section-2-card top-card">
                <div className="software-integration-services-section-2-card-title">
                  <div className="software-integration-services-section-2-card-title-number">
                    1
                  </div>
                  <div className="software-integration-services-section-2-card-title-name">
                    Tailored Integration Solutions
                  </div>
                </div>
                <div className="software-integration-services-section-2-card-desc">
                  <ul>
                    <li>
                      Custom integration solutions designed to connect disparate
                      systems, optimizing workflow efficiency.{" "}
                    </li>
                    <li>
                      Tailor-made strategies to seamlessly integrate new
                      software into your existing infrastructure.{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="software-integration-services-section-2-card">
                <div className="software-integration-services-section-2-card-title">
                  <div className="software-integration-services-section-2-card-title-number">
                    3
                  </div>
                  <div className="software-integration-services-section-2-card-title-name">
                    Legacy System Upgrades{" "}
                  </div>
                </div>
                <div className="software-integration-services-section-2-card-desc">
                  <ul>
                    <li>
                      Upgrading legacy systems to modern technologies for
                      improved functionality and performance.{" "}
                    </li>
                    <li>
                      Future-proofing your business by keeping your software
                      infrastructure up-to-date.{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="software-integration-services-section-2-card">
                <div className="software-integration-services-section-2-card-title">
                  <div className="software-integration-services-section-2-card-title-number">
                    5
                  </div>
                  <div className="software-integration-services-section-2-card-title-name">
                    Security-First Approach{" "}
                  </div>
                </div>
                <div className="software-integration-services-section-2-card-desc">
                  <ul>
                    <li>
                      Prioritizing data security throughout the integration and
                      migration processes.{" "}
                    </li>
                    <li>
                      Implementing robust security measures to protect sensitive
                      information during transitions.{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="container-software-integration-design-process">
        <img
          src={isMobile ? WebDesignMobile : WebDesign}
          alt="software-integration-design"
        />
      </div>

      <div className="container-latest-work">
        <div className="work-card">
          <LatestWorksPage></LatestWorksPage>
        </div>
      </div>

      <div className="home-lets-get-started">
        <LetsGetStarted></LetsGetStarted>
      </div>

      <div className="software-integration-footer mt-20 pb-20 footer-service">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default SoftwareIntegrationService;
