import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/EcommerceSolution.css";
import WebDesign from "../assets/ecommerce-solution-design-process.png";
import { useNavigate } from "react-router-dom";
import WebDesignMobile from "../assets/ecommerce-solution-design-process-mobile.png";
import LatestWorksPage from "../components/LatestWorks";
import LetsGetStarted from "../components/LetsGetStarted";

const EcommerceSolution = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleButtonClick = () => {
    navigate("/contact-us");
  };

  return (
    <div className="container-ecommerce-solution">
      <div className="ecommerce-solution-navbar navbar-service">
        <Navbar></Navbar>
      </div>

      <div className="container-ecommerce-solution-content">
        <div className="ecommerce-solution-header">
          <div className="ecommerce-solution-header-box">
            <div className="ecommerce-solution-header-title-box">
              E-commerce Solutions Service
            </div>
            <div className="ecommerce-solution-header-title">
              <p>E-commerce Solutions</p>
              <p>Tailored for Success</p>
            </div>
            <div className="ecommerce-solution-header-desc">
              Welcome to Lakuuu Digital, your gateway to a thriving online
              presence. Our E-commerce Solutions are designed to empower your
              business in the digital marketplace, providing a seamless and
              secure shopping experience for your customers.
            </div>
            <button
              className="ecommerce-solution-header-btn"
              onClick={handleButtonClick}
            >
              Get in Touch
            </button>
          </div>
        </div>
      </div>

      <div className="container-ecommerce-solution-services">
        {isMobile ? (
          <div className="ecommerce-solution-services-section-1">
            <div className="ecommerce-solution-services-section-1-title">
              <p>Why Choose Our</p>
              <p className="orange-text-ecommerce-solution">E-commerce</p>
              <p>Solutions?</p>
            </div>

            <div className="ecommerce-solution-services-section-1-card">
              <div className="ecommerce-solution-services-section-1-card-title">
                <div className="ecommerce-solution-services-section-1-card-title-number">
                  1
                </div>
                <div className="ecommerce-solution-services-section-1-card-title-name">
                  Custom E-commerce Platforms
                </div>
              </div>
              <div className="ecommerce-solution-services-section-1-card-desc">
                <ul>
                  <li>
                    Tailored e-commerce solutions that align with your brand,
                    ensuring a unique and memorable online storefront.
                  </li>
                  <li>
                    Scalable platforms built to grow with your business,
                    accommodating increasing demands.
                  </li>
                </ul>
              </div>
            </div>

            <div className="ecommerce-solution-services-section-1-card">
              <div className="ecommerce-solution-services-section-1-card-title">
                <div className="ecommerce-solution-services-section-1-card-title-number">
                  2
                </div>
                <div className="ecommerce-solution-services-section-1-card-title-name">
                  User-Friendly Shopping Experiences
                </div>
              </div>
              <div className="ecommerce-solution-services-section-1-card-desc">
                <ul>
                  <li>
                    Intuitive and visually appealing interfaces that enhance
                    user engagement and encourage conversions.
                  </li>
                  <li>
                    Streamlined navigation and responsive design for a seamless
                    shopping journey across devices.
                  </li>
                </ul>
              </div>
            </div>

            <div className="ecommerce-solution-services-section-1-card">
              <div className="ecommerce-solution-services-section-1-card-title">
                <div className="ecommerce-solution-services-section-1-card-title-number">
                  3
                </div>
                <div className="ecommerce-solution-services-section-1-card-title-name">
                  Secure Payment Gateways
                </div>
              </div>
              <div className="ecommerce-solution-services-section-1-card-desc">
                <ul>
                  <li>
                    Implementation of robust and secure payment gateways,
                    instilling trust and confidence in your customers.
                  </li>
                  <li>
                    Multiple payment options to cater to diverse customer
                    preferences.
                  </li>
                </ul>
              </div>
            </div>

            <div className="ecommerce-solution-services-section-1-card">
              <div className="ecommerce-solution-services-section-1-card-title">
                <div className="ecommerce-solution-services-section-1-card-title-number">
                  4
                </div>
                <div className="ecommerce-solution-services-section-1-card-title-name">
                  Efficient Inventory Management
                </div>
              </div>
              <div className="ecommerce-solution-services-section-1-card-desc">
                <ul>
                  <li>
                    Tools and features for easy inventory management, ensuring
                    accurate product listings and availability.
                  </li>
                  <li>
                    Real-time updates to keep your online store in sync with
                    your physical inventory.
                  </li>
                </ul>
              </div>
            </div>

            <div className="ecommerce-solution-services-section-1-card">
              <div className="ecommerce-solution-services-section-1-card-title">
                <div className="ecommerce-solution-services-section-1-card-title-number">
                  5
                </div>
                <div className="ecommerce-solution-services-section-1-card-title-name">
                  Integrated Marketing and Analytics
                </div>
              </div>
              <div className="ecommerce-solution-services-section-1-card-desc">
                <ul>
                  <li>
                    Incorporation of marketing tools and analytics to track
                    performance and optimize your online presence.
                  </li>
                  <li>
                    Strategies to enhance visibility, attract traffic, and boost
                    sales.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="ecommerce-solution-services-section-1">
              <div className="ecommerce-solution-services-section-1-title">
                <p>Why Choose Our</p>
                <p className="orange-text-ecommerce-solution">E-commerce</p>
                <p>Solutions?</p>
              </div>

              <div className="ecommerce-solution-services-section-1-card">
                <div className="ecommerce-solution-services-section-1-card-title">
                  <div className="ecommerce-solution-services-section-1-card-title-number">
                    2
                  </div>
                  <div className="ecommerce-solution-services-section-1-card-title-name">
                    User-Friendly Shopping Experiences
                  </div>
                </div>
                <div className="ecommerce-solution-services-section-1-card-desc">
                  <ul>
                    <li>
                      Intuitive and visually appealing interfaces that enhance
                      user engagement and encourage conversions.
                    </li>
                    <li>
                      Streamlined navigation and responsive design for a
                      seamless shopping journey across devices.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ecommerce-solution-services-section-1-card">
                <div className="ecommerce-solution-services-section-1-card-title">
                  <div className="ecommerce-solution-services-section-1-card-title-number">
                    4
                  </div>
                  <div className="ecommerce-solution-services-section-1-card-title-name">
                    Efficient Inventory Management
                  </div>
                </div>
                <div className="ecommerce-solution-services-section-1-card-desc">
                  <ul>
                    <li>
                      Tools and features for easy inventory management, ensuring
                      accurate product listings and availability.
                    </li>
                    <li>
                      Real-time updates to keep your online store in sync with
                      your physical inventory.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="ecommerce-solution-services-section-2">
              <div className="ecommerce-solution-services-section-2-card top-card">
                <div className="ecommerce-solution-services-section-2-card-title">
                  <div className="ecommerce-solution-services-section-2-card-title-number">
                    1
                  </div>
                  <div className="ecommerce-solution-services-section-2-card-title-name">
                    Custom E-commerce Platforms
                  </div>
                </div>
                <div className="ecommerce-solution-services-section-2-card-desc">
                  <ul>
                    <li>
                      Tailored e-commerce solutions that align with your brand,
                      ensuring a unique and memorable online storefront.
                    </li>
                    <li>
                      Scalable platforms built to grow with your business,
                      accommodating increasing demands.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ecommerce-solution-services-section-2-card">
                <div className="ecommerce-solution-services-section-2-card-title">
                  <div className="ecommerce-solution-services-section-2-card-title-number">
                    3
                  </div>
                  <div className="ecommerce-solution-services-section-2-card-title-name">
                    Secure Payment Gateways
                  </div>
                </div>
                <div className="ecommerce-solution-services-section-2-card-desc">
                  <ul>
                    <li>
                      Implementation of robust and secure payment gateways,
                      instilling trust and confidence in your customers.
                    </li>
                    <li>
                      Multiple payment options to cater to diverse customer
                      preferences.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ecommerce-solution-services-section-2-card">
                <div className="ecommerce-solution-services-section-2-card-title">
                  <div className="ecommerce-solution-services-section-2-card-title-number">
                    5
                  </div>
                  <div className="ecommerce-solution-services-section-2-card-title-name">
                    Integrated Marketing and Analytics
                  </div>
                </div>
                <div className="ecommerce-solution-services-section-2-card-desc">
                  <ul>
                    <li>
                      Incorporation of marketing tools and analytics to track
                      performance and optimize your online presence.
                    </li>
                    <li>
                      Strategies to enhance visibility, attract traffic, and
                      boost sales.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="container-ecommerce-solution-design-process">
        <img
          src={isMobile ? WebDesignMobile : WebDesign}
          alt="ecommerce-solution-design"
        />
      </div>

      <div className="container-latest-work">
        <div className="work-card">
          <LatestWorksPage></LatestWorksPage>
        </div>
      </div>

      <div className="home-lets-get-started">
        <LetsGetStarted></LetsGetStarted>
      </div>

      <div className="ecommerce-solution-footer mt-20 pb-20 footer-service">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default EcommerceSolution;
