import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import LetsGetStarted from 'src/components/LetsGetStarted';

const works = [
  {
    title: 'Website Hotel',
    category: 'UI/UX Design',
    imageUrl: require('../assets/website-hotel.png'),
    link: '/ui-ux-service-portfolio',
  },
  {
    title: 'Portal & Supplier Medical System',
    category: 'Custom Software Development',
    imageUrl: require('../assets/medical-system.png'),
    link: '/custom-software-development-service-portfolio',
  },
  {
    title: 'Pharmacy App',
    category: 'Mobile App Development',
    imageUrl: require('../assets/pharmacy-app.png'),
    link: '/mobile-app-development-service-portfolio',
  },
  {
    title: 'Cookies Online Shop',
    category: 'E-commerce Solutions',
    imageUrl: require('../assets/cookies-shop.png'),
    link: '/ecommerce-solutions-service-portfolio',
  },
  {
    title: 'Employee Medical Check Up',
    category: 'Software Integration and Migration',
    imageUrl: require('../assets/medical-checkup.png'),
    link: '/software-integration-and-migration-service-portfolio',
  },
];

const OurWorks = () => {
  return (
    <div className="bg-[#FAFAFA] relative">
      <div className="fixed top-0 z-[1000] w-full md:w-4/5 mx-auto left-1/2 transform -translate-x-1/2 px-3 md:px-0">
        <Navbar />
      </div>
      <div className="md:w-4/5 mx-auto pt-40 pb-12 relative z-0">
        <div className="flex flex-col lg:flex-row lg:items-center mb-6 px-3 md:px-0">
          <h2 className="text-[39px] md:text-[55px] font-bold mr-4 text-[#2B2A29] font-poppins md:mr-20 md:leading-none whitespace-nowrap">
            Our Works
          </h2>
          <p className="text-[4vw] md:text-[16px] font-poppins text-black mt-5 md:mt-0">
            Explore our portfolio of diverse projects, showcasing our expertise across various services. Each project represents our dedication to delivering high-quality solutions tailored to our clients' needs.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 px-3 md:px-0 mt-10">
          {works.map((work, index) => (
            <a
              key={index}
              href={work.link}
              className="relative z-10 bg-white rounded-lg overflow-hidden h-[210px] md:h-[370px] w-full"
            >
              <div className="absolute top-0 right-0 m-2">
                <button className="bg-[#FFFAF5] border border-[#FFD8B5] text-[#FD7600] text-xs md:text-sm px-2 py-1 rounded-full">
                  {work.category}
                </button>
              </div>
              <div
                className="bg-cover bg-center h-full flex items-center justify-center"
                style={{ backgroundImage: `url(${work.imageUrl})` }}
              >
                <div className="absolute bottom-0 text-white w-full p-4 text-center">
                  <h3 className="text-lg md:text-xl font-semibold font-poppins md:text-[24px] md:leading-none mb-3">
                    {work.title}
                  </h3>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="bg-[#fafafa] w-full md:w-4/5 mx-auto pt-12 mb-12 px-3 md:px-0">
        <LetsGetStarted />
      </div>
      <div className="bg-[#fafafa] w-full md:w-4/5 mx-auto px-3 md:px-0">
        <Footer />
      </div>
    </div>
  );
};

export default OurWorks;