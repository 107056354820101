
import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/MobileAppDevelopmentService.css";
import WebDesign from "../assets/mobile-app-development-design-process.png";
import { useNavigate } from "react-router-dom";
import WebDesignMobile from "../assets/mobile-app-development-design-process-mobile.png";
import LatestWorksPage from "../components/LatestWorks";
import LetsGetStarted from "../components/LetsGetStarted";

const MobileAppDevelopmentService = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleButtonClick = () => {
    navigate("/contact-us");
  };

  return (
    <div className="container-mobile-app-development">
      <div className="mobile-app-development-navbar navbar-service">
        <Navbar></Navbar>
      </div>

      <div className="container-mobile-app-development-content">
        <div className="mobile-app-development-header">
          <div className="mobile-app-development-header-box">
            <div className="mobile-app-development-header-title-box">
            Mobile App Development Service
            </div>
            <div className="mobile-app-development-header-title">
              <p>Innovate and Captivate with</p>
              <p>
             Our Mobile App Solutions</p>
            </div>
            <div className="mobile-app-development-header-desc">
            Welcome to Lakuuu Digital, where we turn your ideas into dynamic, user-centric mobile applications. Our Mobile App Development services are crafted to elevate your brand, engage your audience, and deliver a seamless digital experience on the go.
            </div>
            <button
              className="mobile-app-development-header-btn"
              onClick={handleButtonClick}
            >
              Get in Touch
            </button>
          </div>
        </div>
      </div>

      <div className="container-mobile-app-development-services">
        {isMobile ? (
          <div className="mobile-app-development-services-section-1">
            <div className="mobile-app-development-services-section-1-title">
              <p>Why Opt for Our</p>
              <p className="orange-text-mobile-app-development">Mobile App</p>
              <p className="orange-text-mobile-app-development">Development</p>
              <p>Service?</p>
            </div>

            <div className="mobile-app-development-services-section-1-card">
              <div className="mobile-app-development-services-section-1-card-title">
                <div className="mobile-app-development-services-section-1-card-title-number">
                  1
                </div>
                <div className="mobile-app-development-services-section-1-card-title-name">
                Bespoke Solutions
                </div>
              </div>
              <div className="mobile-app-development-services-section-1-card-desc">
                <ul>
                  <li>
                  Custom-built mobile apps tailored to your unique business requirements, ensuring a perfect fit.
                  </li>
                  <li>
                  Innovative solutions that align with your vision and set your brand apart.
                  </li>
                </ul>
              </div>
            </div>

            <div className="mobile-app-development-services-section-1-card">
              <div className="mobile-app-development-services-section-1-card-title">
                <div className="mobile-app-development-services-section-1-card-title-number">
                  2
                </div>
                <div className="mobile-app-development-services-section-1-card-title-name">
                Cross-Platform Expertise
                </div>
              </div>
              <div className="mobile-app-development-services-section-1-card-desc">
                <ul>
                  <li>
                  Proficiency in developing applications for both iOS and Android platforms, reaching a wider audience.
                  </li>
                  <li>
                  Consistent performance and user experience across diverse devices.
                  </li>
                </ul>
              </div>
            </div>

            <div className="mobile-app-development-services-section-1-card">
              <div className="mobile-app-development-services-section-1-card-title">
                <div className="mobile-app-development-services-section-1-card-title-number">
                  3
                </div>
                <div className="mobile-app-development-services-section-1-card-title-name">
                User-Centric Design
                </div>
              </div>
              <div className="mobile-app-development-services-section-1-card-desc">
                <ul>
                  <li>
                  A focus on intuitive interfaces and user-friendly designs that enhance engagement.
                  </li>
                  <li>
                  We prioritize the end-user experience, making your app a joy to use.
                  </li>
                </ul>
              </div>
            </div>

            <div className="mobile-app-development-services-section-1-card">
              <div className="mobile-app-development-services-section-1-card-title">
                <div className="mobile-app-development-services-section-1-card-title-number">
                  4
                </div>
                <div className="mobile-app-development-services-section-1-card-title-name">
                  Scalability and Performance
                </div>
              </div>
              <div className="mobile-app-development-services-section-1-card-desc">
                <ul>
                  <li>
                  Future-proof solutions that scale with your business growth, maintaining optimal performance.
                  </li>
                  <li>
                  Robust architecture and coding practices for a smooth and responsive app.
                  </li>
                </ul>
              </div>
            </div>

            <div className="mobile-app-development-services-section-1-card">
              <div className="mobile-app-development-services-section-1-card-title">
                <div className="mobile-app-development-services-section-1-card-title-number">
                  5
                </div>
                <div className="mobile-app-development-services-section-1-card-title-name">
                Agile Development Process
                </div>
              </div>
              <div className="mobile-app-development-services-section-1-card-desc">
                <ul>
                  <li>
                  Embrace flexibility with our agile development methodology, ensuring quick iterations and adaptability.
                  </li>
                  <li>
                  Stay ahead in the rapidly evolving mobile landscape.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="mobile-app-development-services-section-1">
              <div className="mobile-app-development-services-section-1-title">
              <p>Why Opt for Our</p>
              <p className="orange-text-mobile-app-development">Mobile App</p>
              <p className="orange-text-mobile-app-development">Development</p>
              <p>Service?</p>
              </div>

              <div className="mobile-app-development-services-section-1-card">
              <div className="mobile-app-development-services-section-1-card-title">
                <div className="mobile-app-development-services-section-1-card-title-number">
                  2
                </div>
                <div className="mobile-app-development-services-section-1-card-title-name">
                Cross-Platform Expertise
                </div>
              </div>
              <div className="mobile-app-development-services-section-1-card-desc">
                <ul>
                  <li>
                  Proficiency in developing applications for both iOS and Android platforms, reaching a wider audience.
                  </li>
                  <li>
                  Consistent performance and user experience across diverse devices.
                  </li>
                </ul>
              </div>
            </div>

            <div className="mobile-app-development-services-section-1-card">
              <div className="mobile-app-development-services-section-1-card-title">
                <div className="mobile-app-development-services-section-1-card-title-number">
                  4
                </div>
                <div className="mobile-app-development-services-section-1-card-title-name">
                  Scalability and Performance
                </div>
              </div>
              <div className="mobile-app-development-services-section-1-card-desc">
                <ul>
                  <li>
                  Future-proof solutions that scale with your business growth, maintaining optimal performance.
                  </li>
                  <li>
                  Robust architecture and coding practices for a smooth and responsive app.
                  </li>
                </ul>
              </div>
            </div>
            </div>

            <div className="mobile-app-development-services-section-2">
              <div className="mobile-app-development-services-section-2-card top-card">
              <div className="mobile-app-development-services-section-2-card-title">
                <div className="mobile-app-development-services-section-2-card-title-number">
                  1
                </div>
                <div className="mobile-app-development-services-section-2-card-title-name">
                Bespoke Solutions
                </div>
              </div>
              <div className="mobile-app-development-services-section-2-card-desc">
                <ul>
                  <li>
                  Custom-built mobile apps tailored to your unique business requirements, ensuring a perfect fit.
                  </li>
                  <li>
                  Innovative solutions that align with your vision and set your brand apart.
                  </li>
                </ul>
              </div>
              </div>

              <div className="mobile-app-development-services-section-2-card">
              <div className="mobile-app-development-services-section-2-card-title">
                <div className="mobile-app-development-services-section-2-card-title-number">
                  3
                </div>
                <div className="mobile-app-development-services-section-2-card-title-name">
                User-Centric Design
                </div>
              </div>
              <div className="mobile-app-development-services-section-2-card-desc">
                <ul>
                  <li>
                  A focus on intuitive interfaces and user-friendly designs that enhance engagement.
                  </li>
                  <li>
                  We prioritize the end-user experience, making your app a joy to use.
                  </li>
                </ul>
              </div>
            </div>

            <div className="mobile-app-development-services-section-2-card">
              <div className="mobile-app-development-services-section-2-card-title">
                <div className="mobile-app-development-services-section-2-card-title-number">
                  5
                </div>
                <div className="mobile-app-development-services-section-2-card-title-name">
                Agile Development Process
                </div>
              </div>
              <div className="mobile-app-development-services-section-2-card-desc">
                <ul>
                  <li>
                  Embrace flexibility with our agile development methodology, ensuring quick iterations and adaptability.
                  </li>
                  <li>
                  Stay ahead in the rapidly evolving mobile landscape.
                  </li>
                </ul>
              </div>
            </div>
            </div>
          </>
        )}
      </div>

      <div className="container-mobile-app-development-design-process">
        <img
          src={isMobile ? WebDesignMobile : WebDesign}
          alt="mobile-app-development-design"
        />
      </div>

      <div className="container-latest-work">
        <div className="work-card">
          <LatestWorksPage></LatestWorksPage>
        </div>
      </div>

      <div className="home-lets-get-started">
        <LetsGetStarted></LetsGetStarted>
      </div>

      <div className="mobile-app-development-footer mt-20 pb-20 footer-service">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default MobileAppDevelopmentService;
