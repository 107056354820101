import '../styles/popup.css'; // Ensure this contains styles for the popup
import successIcon  from '../assets/success-popup-icon.svg'

interface MessagePopupProps {
  message: string;
  isOpen: boolean;
  status: string;
}

const MessagePopup = ({ message, isOpen, status }: MessagePopupProps) => {

  return (
    <>
      {isOpen && (
        <div className="popup-overlay">
          <div className={`popup-content ${status === 'success' ? 'success-popup' : 'failed-popup'}`}>
            <img src={successIcon} alt="success" />
            <p className="popup-message">{message}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default MessagePopup;
