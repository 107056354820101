// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LatestWorksPage from './components/LatestWorks';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import ContactUs from './pages/ContactUs';
import Footer from './components/Footer';
import MobileAppDevelopmentServicePortfolioPage from './pages/MobileAppDevelopmentServicePortfolio';
import EcommerceSolutionsServicePortfolioPage from './pages/EcommerceSolutionsServicePortfolio';
import WebDevelopmentServicePortfolioPage from './pages/WebDevelopmentServicePorfolio';
import SoftwareIntegrationAndMigrationServicePortfolioPage from './pages/SoftwareIntegrationandMigrationServicePortfolio';
import UiUxDesignService from './pages/UiUxDesignService';
import CustomSoftwareDevelopment from './pages/CustomSoftwareDevelopment';
import WebDevelopmentService from './pages/WebDevelopmentService';
import MobileAppDevelopmentService from './pages/MobileAppDevelopmentService';
import SoftwareIntegrationService from './pages/SoftwareIntegrationService';
import UIUXServicePortfolioPage from './pages/UIUXServicePortfolio';
import CustomSoftwareDevelopmentServicePortfolioPage from './pages/CustomSoftwareDevelopmentServicePorfolio';
import EcommerceSolution from './pages/EcommerceSolution';
import QualityAssuranceAndTestingService from './pages/QualityAssuranceAndTestingService';
import OurWorksPage from './pages/OurWorks';


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/mobile-app-development-service-portfolio" element={<MobileAppDevelopmentServicePortfolioPage />} />
        <Route path="/ecommerce-solutions-service-portfolio" element={<EcommerceSolutionsServicePortfolioPage />} />
        <Route path="/web-development-service-portfolio" element={<WebDevelopmentServicePortfolioPage />} />
        <Route path="/software-integration-and-migration-service-portfolio" element={<SoftwareIntegrationAndMigrationServicePortfolioPage />} />
        <Route path="/ui-ux-service-portfolio" element={<UIUXServicePortfolioPage />} />
        <Route path="/custom-software-development-service-portfolio" element={<CustomSoftwareDevelopmentServicePortfolioPage />} />
        <Route path="/components/navbar" element={<Navbar />} />
        <Route path="/components/footer" element={<Footer />} />
        <Route path="/components/latest-works" element={<LatestWorksPage />} />
        <Route path="/ui-ux" element={<UiUxDesignService />} />
        <Route path="/custom-software-development" element={<CustomSoftwareDevelopment />} />
        <Route path="/development-service" element={<WebDevelopmentService />} />
        <Route path="/mobile-app-development-service" element={<MobileAppDevelopmentService />} />
        <Route path="/software-integration-service" element={<SoftwareIntegrationService />} />
        <Route path="/ecommerce-solution" element={<EcommerceSolution />} />
        <Route path="/quality-assurance" element={<QualityAssuranceAndTestingService />} />
        <Route path="/our-works" element={<OurWorksPage />} />
      </Routes>
    </Router>
  );
};

export default App;

