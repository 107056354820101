import { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../styles/latestWork.css';

// Import images
import websiteHotel from '../assets/website-hotel.png';
import cookiesShop from '../assets/cookies-shop.png';
import medicalCheckup from '../assets/medical-checkup.png';
import medicalSystem from '../assets/medical-system.png';
import pharmacyApp from '../assets/pharmacy-app.png';

const images = [websiteHotel, medicalSystem, pharmacyApp, cookiesShop, medicalCheckup];
const texts = [
  'UI & UX Design', 
  'Custom Software Development', 
  'Mobile App Development', 
  'E-commerce Solution',
  'Software Integration and Migration'
];

const textMap: { [key: string]: string } = {
  'UI & UX Design': 'Website Hotel',
  'Custom Software Development': 'Portal & Supplier Medical System',
  'Mobile App Development': 'Pharmacy App',
  'E-commerce Solution': 'Cookies Online Shop',
  'Software Integration and Migration': 'Employee Medical Check Up '
};

const urls: { [key: string]: string } = {
  'UI & UX Design': '/ui-ux-service-portfolio',
  'Custom Software Development': '/custom-software-development-service-portfolio',
  'Mobile App Development': '/mobile-app-development-service-portfolio',
  'E-commerce Solution': '/ecommerce-solutions-service-portfolio',
  'Software Integration and Migration': '/software-integration-and-migration-service-portfolio'
};

const LatestWorksPage = () => {
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleMouseDown = () => {
    setIsDragging(false);
  };

  const handleMouseMove = () => {
    setIsDragging(true);
  };

  const handleMouseUp = (index: number, url: string) => {
    if (!isDragging) {
      setClickedIndex(index);
      window.location.href = url;
    }
    setIsDragging(false);
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    centerMode: false,
    rows: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        }
      }
    ]
  };

  return (
    <div className="latest-works-page p-20">
      <div className="header w-full flex justify-between py-8 md:order-1">
        <div className="title font-family ml-6">
          Latest Works
        </div>
        <button
          className={`see-other-works label flex items-center font-family px-4 py-2 text-sm leading-6 h-10 mt-6 ${clickedIndex === -1 ? 'clicked' : ''}`}
          onClick={() => window.location.href = '/our-works'}
        >
          See Other Works
        </button>
      </div>
      <div className="carousel-container md:order-2 mt-6 md:mt-0">
        <Slider {...settings}>
          {texts.map((text, index) => (
            <div key={index} className="carousel-item box-border w-full px-6"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={() => handleMouseUp(index, urls[text])}>
              <div className="card-link cursor-pointer">
                <div className="card" style={{ backgroundImage: `url(${images[index]})` }}>
                  <div className="card-content">
                    <div className={`card-text label font-family px-4 py-2 ${clickedIndex === index ? 'clicked' : ''}`}>
                      {text}
                    </div>
                  </div>
                  {textMap[text] && <p className="card-extra-text font-family">{textMap[text]}</p>}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <button
        className={`see-other-works-mobile flex items-center label font-family px-4 py-2 text-sm leading-6 h-10 mt-6 md:hidden ${clickedIndex === -1 ? 'clicked' : ''}`}
        onClick={() => window.location.href = '/our-works'}
      >
        See Other Works
      </button>
    </div>
  );
};

export default LatestWorksPage;