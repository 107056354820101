
import LetsGetStarted from "../components/LetsGetStarted";
import LatestWorksPage from "../components/LatestWorks";
import Navbar from "../components/Navbar";
import "../styles/home.css";
import peopleImage from "../assets/home-image-people.png";
import missionLogo from "../assets/mission-logo.svg";
import visionLogo from "../assets/vision-logo.svg";
import buttonRight from "../assets/button-right.svg";
import { Link } from "react-router-dom";
import frontendDesktop from "../assets/frontend-desktop.png";
import backendDesktop from "../assets/backend-desktop.png";
import databaseDesktop from "../assets/database-desktop.svg";
import infrastructureDesktop from "../assets/infrastructure-desktop.png";
import backendMobile from "../assets/backend-mobile.png";
import { useMediaQuery } from "react-responsive";
import iconDwidaya from "../assets/icon-dwidaya-tour.svg";
import iconLia from "../assets/icon-lia.svg";
import iconMidispensary from "../assets/icon-midispensary.svg";
import iconPeternakan from "../assets/icon-peternakan.svg";
import iconEntourage from "../assets/icon-entourage.svg";
import partnerArumi from "../assets/partner-arumi.svg";
import partnerBendung from "../assets/partner-bendung.svg";
import partnerDreamsDuck from "../assets/partner-dreamsduck.svg";
import partnerMiscast from "../assets/partner-miscast.png";
import partnerNafila from "../assets/partner-nafila.svg";
import partnerPec from "../assets/partner-pec.svg";
import partnerTjingTjau from "../assets/partner-tjing-tjau.svg";
import partnerVenus from "../assets/partner-venus.svg";
import partnerGraciaBox from "../assets/partnner-gracia-box.svg";
import chooseUsGroupIcon from '../assets/choose-us-group-icon.svg';
import chooseUsTechIcon from '../assets/choose-us-tech-icon.svg';
import chooseUsApproveIcon from '../assets/choose-us-approve-icon.svg';
import testimoniesCardIcon from '../assets/testimonies-card-icon.svg'
import Footer from "../components/Footer";
import { useRef, useState } from 'react';

const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - (scrollContainerRef.current?.offsetLeft || 0));
    setScrollLeft(scrollContainerRef.current?.scrollLeft || 0);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const x = e.pageX - (scrollContainerRef.current?.offsetLeft || 0);
    const walk = x - startX;
    scrollContainerRef.current!.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const testimonies = [
    {
      desc: 'Lakuuu team was proactive all the way throughout the project. They even came up with ideas to improve our website, and now the web is running better than ever. We are bug-free and have way less customer complaints now. It really drives sales too!"',
      name: 'Mr. Andy H',
      job: 'Project Owner of MiDispensary', 
      address: 'New South Wales, Australia'
    },
    {
      desc: 'Lakuuu sangat mendorong UMKM di komunitas kami untuk lebih melek teknologi. Aplikasinya juga sangat mudah dipakai, hingga sekarang tidak ada lagi data yang harus kami cari-cari. Kami fokus ke produktivitas saja."',
      name: 'Bpk. Suwarna G.',
      job: 'Vice President UMKM', 
      address: 'Kota Tangerang, Indonesia'
    },
    {
      desc: 'Project selalu selesai tepat waktu, dan tim Lakuuu memberikan malah lebih dari harapan saya. Alhamdullilah bisnis hotel kembali membaik, dan kami sudah siap dengan booking system baru."',
      name: 'Bpk. Bimo B.',
      job: 'Project Owner of Legacy Hotel', 
      address: 'Bekasi, Indonesia'
    },
  ]

  const partnerList = [
    {
      image: partnerArumi,
    },
    {
      image: partnerBendung,
    },
    {
      image: partnerDreamsDuck,
    },
    {
      image: partnerMiscast,
    },
    {
      image: partnerNafila,
    },
    {
      image: partnerPec,
    },
    {
      image: partnerTjingTjau,
    },
    {
      image: partnerVenus,
    },
    {
      image: partnerGraciaBox,
    },
  ];

  const listProposition = [
    {
      title: "UI UX Design",
      redirect: "/ui-ux-design",
    },
    {
      title: "Custom Software Development",
      redirect: "/custom-software-development",
    },
    {
      title: "Web Development",
      redirect: "/web-development",
    },
    {
      title: "Mobile App Development",
      redirect: "/mobile-app-development",
    },
    {
      title: "Software Integration and Migration",
      redirect: "/software-integration-and-migration",
    },
    {
      title: "E-commerce Solutions",
      redirect: "/e-commerce-solutions",
    },
    {
      title: "Quality Assurance and Testing",
      redirect: "/quality-assurance-and-testing",
    },
  ];

  const listClientKey = [
    {
      title: "Departemen Peternakan - Timbangan Application",
      description:
        "Farm Weigh App: Input crop details for precise measurements and real-time insights, streamlining farm management and ensuring regulatory compliance.",
      image: iconPeternakan,
    },
    {
      title: "Customer Portal",
      description:
        "User-friendly platform for account management, transaction history, and business interaction, enhancing overall customer experience.",
      image: iconEntourage,
    },
    {
      title: "Clinic & Pharmacy Web",
      description:
        "Integrates clinic and pharmacy operations, offering online features for appointments, prescription refills, and patient data, optimizing healthcare services.",
      image: iconMidispensary,
    },
    {
      title: "Student Portal",
      description:
        "Centralized platform for student access to academic info, course registration, grades, and faculty communication, streamlining education processes.",
      image: iconLia,
    },
    {
      title: "Tour & Travel Management System",
      description:
        "Centralized system streamlines travel operations, including itinerary planning, reservations, and customer data, optimizing booking efficiency for travel agencies.",
      image: iconDwidaya,
    },
  ];

  return (
    <div className="home">
      <div className="home-navbar navbar-service">
        <Navbar></Navbar>
      </div>
      <div className="container-title">
        <div className="container-title-content">
          <div className="title-product">Lakuuu Digital</div>
          <div className="title-content">
            Empowering Innovations Delivering Value
          </div>
          <div className="content">
            Where we believe in the power of innovation and the delivery of
            exceptional value. As a cutting-edge software house, we are
            committed to transforming ideas into reality and empowering
            businesses through digital solutions.
          </div>
          <button className="btn-contact-us">Get in Touch</button>
        </div>
      </div>
      <div className="container-people-image">
        <img src={peopleImage} alt="people" />
      </div>
      <div className="container-company-overview">
        <div className="company-title">
          <div className="title">
            <p>Company</p>
            <p>Overview</p>
          </div>
          <div className="context">
            <a>PT LAKUUU TEKNOLOGI DIGITAL</a> was established in 2020. Since
            then, with our innovative proprietary platform we have enabled
            thousands of SMEs and corporations achieve their digital
            transformation objectives.
          </div>
        </div>

        <div className="company-content">
          <div className="company-card company-vision">
            <div className="company-card-icon">
              <img src={visionLogo} alt="vision-logo" />
            </div>
            <div className="company-card-title">Our Vision</div>
            <div className="company-card-content">
              Lakuuu is a leading provider of innovative technology solutions
              for businesses worldwide. With a strong focus on cutting-edge
              technologies, we partner with our clients to harness the power of
              digital transformation to drive growth and stay ahead in today's
              competitive landscape.
            </div>
          </div>
          <div className="company-card company-mission">
            <div className="company-card-icon">
              <img src={missionLogo} alt="mission-logo" />
            </div>
            <div className="company-card-title">Our Mission</div>
            <div className="company-card-content">
              At Lakuuu, our mission is to empower businesses with
              transformative technology solutions that enhance efficiency,
              productivity, and profitability. We strive to be a trusted partner
              for our clients, delivering exceptional values through
              innovations, expertise, and unparalleled customer service.
            </div>
          </div>
        </div>
      </div>

      <div className="container-value-proposition">
        <div className="value-proposition-title">
          <div className="head-title">
            <p>Our</p>
            <p>Value Proposition</p>
          </div>
          <div className="body-title">
            We offer a wide range of tailored services to cater your business
            needs.
          </div>
        </div>

        <div className="value-proposition-list-card">
          {listProposition.map((item, index) => (
            <div className="card-proposition" key={index}>
              <div className="card-propositon-title">{item.title}</div>
              <Link to={item.redirect}>
                <button>
                  <img src={buttonRight} alt="button-right" />
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className="container-expertise">
        <div className="expertise-title">
          <div className="main-title">
            <p>Our</p>
            <p>Expertise</p>
          </div>

          <div className="context-title">
            Tech Stack within our capabilities
          </div>
        </div>

        <div className="list-tech-stack">
          <div className="card-tech">
            <p>Front-End</p>
            <img src={frontendDesktop} alt="frontend-desktop" />
          </div>
          <div className="card-tech">
            <p>Back-End</p>
            <img
              src={isMobile ? backendMobile : backendDesktop}
              alt="backend-desktop"
            />
          </div>
          <div className="sub-card-tech">
            <div className="card-tech">
              <p>Database</p>
              <img src={databaseDesktop} alt="database-desktop" />
            </div>
            <div className="card-tech">
              <p>Infrastructure</p>
              <img src={infrastructureDesktop} alt="infrastructure-desktop" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-client-key">
        <div className="client-title">Key Clients</div>

        <div className="client-list">
          <div className="client-card-text-only">
            <p>
              Lakuuu is committed to serve a diverse range of industries,
              including manufacturing, healthcare, finance, retail, and
              technology.
            </p>

            <p>
              Our solutions cater to businesses of all sizes, from small
              startups to large enterprises, operating on a global scale.
            </p>

            <p>
              With a track record of successful implementation and a reputation
              in delivering exceptional value, Lakuuu is dedicated to ensure the
              success and growth of our clients through transformative
              technology solutions.
            </p>
          </div>

          {listClientKey.map((item) => (
            <div className="client-card">
              <div className="client-card-icon">
                <img src={item.image} alt="icon-peternakan" />
              </div>

              <div className="client-card-title">{item.title}</div>

              <div className="client-card-content">{item.description}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="container-latest-work">
        <div className="work-card">
          <LatestWorksPage></LatestWorksPage>
        </div>
      </div>

      <div className="container-our-partner">
        <div className="our-partner-title">Our Partners</div>
        <div className="our-partner-list">
          {partnerList.map((partner, index) => (
            <div key={index} className="partner-card">
              <img src={partner.image} alt={`partner-${index}`} />
            </div>
          ))}
        </div>
      </div>

      <div className="container-choose-us">
        <div className="choose-us-title">
          <p>Why</p>
          <p>Choose Us</p>
        </div>
        <div className="choose-us-content-list">
          <div className="choose-us-content-card">
            <div className="choose-us-content-icon">
                <img src={chooseUsTechIcon} alt="vision-logo" />
            </div>
            <div className="choose-us-content-title">Expertise in Tech</div>
            <div className="choose-us-content-desc">
            we excel in diverse technologies. Our team crafts innovative solutions using cutting-edge tools and frameworks, ensuring your project stays ahead in the tech landscape.
            </div>
          </div>

          <div className="choose-us-content-card">
            <div className="choose-us-content-icon">
                <img src={chooseUsApproveIcon} alt="vision-logo" />
            </div>
            <div className="choose-us-content-title">Proven Success Record</div>
            <div className="choose-us-content-desc">
            Our track record speaks volumes. With consistent success across industries, we've earned trust from startups to enterprises. Choose us for a partner with a history of client satisfaction and project excellence.
            </div>
          </div>

          <div className="choose-us-content-card">
            <div className="choose-us-content-icon">
                <img src={chooseUsGroupIcon} alt="vision-logo" />
            </div>
            <div className="choose-us-content-title">Client-Centric Approach</div>
            <div className="choose-us-content-desc">
            We prioritize your needs throughout. Transparent communication, regular updates, and deep understanding of your objectives define our approach. Choose Us for a partner dedicated to surpassing expectations.
            </div>
          </div>
        </div>
      </div>

      <div className="container-client-testimonies">
          <div className="client-testimonies-title">
            <p>
            Client
            </p>

            <p>
            Testimonies
            </p>
          </div>

          <div 
          ref={scrollContainerRef}
          className="client-testimonies-list-card"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          >
          {testimonies.map((testimonie) => (
            <div className="client-testimonies-card">
              <div className="client-testimonies-card-icon">
                <img src={testimoniesCardIcon} alt="testimonies-icon-1" />
              </div>
              <p className="client-testimonies-card-message">
                {testimonie.desc}
              </p>

              <p className="client-testimonies-card-name">
                {testimonie.name}
              </p>

              <p className="client-testimonies-card-name">
                {testimonie.job}
              </p>

              <p className="client-testimonies-card-address">
                {testimonie.address}
              </p>
            </div>
          ))}
          </div>
      </div>

      <div className="container-home-footer">
          <div className="home-lets-get-started">
            <LetsGetStarted></LetsGetStarted>
          </div>
      </div>
      <div className="footer-service">
            <Footer></Footer>
          </div>
    </div>
  );
};

export default Home;
