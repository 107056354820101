import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/QualityAssuranceAndTestingService.css";
import QaDesign from "../assets/quality-assurance-design-process.png";
import { useNavigate } from "react-router-dom";
import QaDesignMobile from "../assets/quality-assurance-design-process-mobile.png";
import LatestWorksPage from "../components/LatestWorks";
import LetsGetStarted from "../components/LetsGetStarted";

const QualityAssuranceAndTestingService = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleButtonClick = () => {
    navigate("/contact-us");
  };

  return (
    <div className="container-quality-assurance">
      <div className="quality-assurance-navbar navbar-service">
        <Navbar></Navbar>
      </div>

      <div className="container-quality-assurance-content">
        <div className="quality-assurance-header">
          <div className="quality-assurance-header-box">
            <div className="quality-assurance-header-title-box">
              Quality Assurance and Testing Service
            </div>

            <div className="quality-assurance-header-title">
              <p>Precision Testing for</p>
              <p>Software Excellence</p>
            </div>
            <div className="quality-assurance-header-desc">
              Welcome to Lakuuu Digital, your dedicated partner in ensuring the
              highest quality for your software solutions. Our Quality Assurance
              and Testing services are designed to meticulously scrutinize every
              aspect of your application, guaranteeing a flawless user
              experience.
            </div>
            <button
              className="quality-assurance-header-btn"
              onClick={handleButtonClick}
            >
              Get in Touch
            </button>
          </div>
        </div>
      </div>

      <div className="container-quality-assurance-services">
        {isMobile ? (
          <div className="quality-assurance-services-section-1">
            <div className="quality-assurance-services-section-1-title">
              <p>Why Opt for Our</p>
              <p className="orange-text-quality-assurance">Quality Assurance</p>
              <p className="orange-text-quality-assurance">
                and Testing<span style={{ color: "black" }}>?</span>
              </p>
            </div>

            <div className="quality-assurance-services-section-1-card">
              <div className="quality-assurance-services-section-1-card-title">
                <div className="quality-assurance-services-section-1-card-title-number">
                  1
                </div>
                <div className="quality-assurance-services-section-1-card-title-name">
                  Holistic Testing Approach
                </div>
              </div>
              <div className="quality-assurance-services-section-1-card-desc">
                <ul>
                  <li>
                    Comprehensive testing covering functionality, performance,
                    security, and usability.
                  </li>
                  <li>
                    Our approach ensures that your software not only works but
                    excels in every aspect.
                  </li>
                </ul>
              </div>
            </div>

            <div className="quality-assurance-services-section-1-card">
              <div className="quality-assurance-services-section-1-card-title">
                <div className="quality-assurance-services-section-1-card-title-number">
                  2
                </div>
                <div className="quality-assurance-services-section-1-card-title-name">
                  Advanced Automation Techniques
                </div>
              </div>
              <div className="quality-assurance-services-section-1-card-desc">
                <ul>
                  <li>
                    Implementation of cutting-edge automated testing tools for
                    efficient and repeatable testing.
                  </li>
                  <li>
                    Accelerated testing cycles without compromising accuracy.
                  </li>
                </ul>
              </div>
            </div>

            <div className="quality-assurance-services-section-1-card">
              <div className="quality-assurance-services-section-1-card-title">
                <div className="quality-assurance-services-section-1-card-title-number">
                  3
                </div>
                <div className="quality-assurance-services-section-1-card-title-name">
                  User-Centric Testing
                </div>
              </div>
              <div className="quality-assurance-services-section-1-card-desc">
                <ul>
                  <li>
                    Testing scenarios designed to replicate real-world user
                    experiences, ensuring your application resonates with your
                    audience.
                  </li>
                  <li>
                    Usability testing to refine the user interface and enhance
                    overall user satisfaction.
                  </li>
                </ul>
              </div>
            </div>

            <div className="quality-assurance-services-section-1-card">
              <div className="quality-assurance-services-section-1-card-title">
                <div className="quality-assurance-services-section-1-card-title-number">
                  4
                </div>
                <div className="quality-assurance-services-section-1-card-title-name">
                  Performance Optimization Strategies
                </div>
              </div>
              <div className="quality-assurance-services-section-1-card-desc">
                <ul>
                  <li>
                    Identification and resolution of performance bottlenecks to
                    guarantee optimal application speed.
                  </li>
                  <li>
                    Scalability testing to prepare your software for increased
                    user loads.
                  </li>
                </ul>
              </div>
            </div>

            <div className="quality-assurance-services-section-1-card">
              <div className="quality-assurance-services-section-1-card-title">
                <div className="quality-assurance-services-section-1-card-title-number">
                  5
                </div>
                <div className="quality-assurance-services-section-1-card-title-name">
                  Security Fortification
                </div>
              </div>
              <div className="quality-assurance-services-section-1-card-desc">
                <ul>
                  <li>
                    Adherence to the highest security standards and best
                    practices.
                  </li>
                  <li>
                    Strategies to enhance visibility, attract traffic, and boost
                    sales.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="quality-assurance-services-section-1">
              <div className="quality-assurance-services-section-1-title">
                <p>Why Opt for Our</p>
                <p className="orange-text-quality-assurance">
                  Quality Assurance
                </p>
                <p className="orange-text-quality-assurance">
                  and Testing<span style={{ color: "black" }}>?</span>
                </p>
              </div>

              <div className="quality-assurance-services-section-1-card">
                <div className="quality-assurance-services-section-1-card-title">
                  <div className="quality-assurance-services-section-1-card-title-number">
                    2
                  </div>
                  <div className="quality-assurance-services-section-1-card-title-name">
                    Advanced Automation Techniques
                  </div>
                </div>
                <div className="quality-assurance-services-section-1-card-desc">
                  <ul>
                    <li>
                      Implementation of cutting-edge automated testing tools for
                      efficient and repeatable testing.
                    </li>
                    <li>
                      Accelerated testing cycles without compromising accuracy.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="quality-assurance-services-section-1-card">
                <div className="quality-assurance-services-section-1-card-title">
                  <div className="quality-assurance-services-section-1-card-title-number">
                    4
                  </div>
                  <div className="quality-assurance-services-section-1-card-title-name">
                    Performance Optimization Strategies
                  </div>
                </div>
                <div className="quality-assurance-services-section-1-card-desc">
                  <ul>
                    <li>
                      Identification and resolution of performance bottlenecks
                      to guarantee optimal application speed.
                    </li>
                    <li>
                      Scalability testing to prepare your software for increased
                      user loads.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="quality-assurance-services-section-2">
              <div className="quality-assurance-services-section-2-card top-card">
                <div className="quality-assurance-services-section-2-card-title">
                  <div className="quality-assurance-services-section-2-card-title-number">
                    1
                  </div>
                  <div className="quality-assurance-services-section-2-card-title-name">
                    Holistic Testing Approach
                  </div>
                </div>
                <div className="quality-assurance-services-section-2-card-desc">
                  <ul>
                    <li>
                      Comprehensive testing covering functionality, performance,
                      security, and usability.
                    </li>
                    <li>
                      Our approach ensures that your software not only works but
                      excels in every aspect.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="quality-assurance-services-section-2-card">
                <div className="quality-assurance-services-section-2-card-title">
                  <div className="quality-assurance-services-section-2-card-title-number">
                    3
                  </div>
                  <div className="quality-assurance-services-section-2-card-title-name">
                    User-Centric Testing
                  </div>
                </div>
                <div className="quality-assurance-services-section-2-card-desc">
                  <ul>
                    <li>
                      Testing scenarios designed to replicate real-world user
                      experiences, ensuring your application resonates with your
                      audience.
                    </li>
                    <li>
                      Usability testing to refine the user interface and enhance
                      overall user satisfaction.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="quality-assurance-services-section-2-card">
                <div className="quality-assurance-services-section-2-card-title">
                  <div className="quality-assurance-services-section-2-card-title-number">
                    5
                  </div>
                  <div className="quality-assurance-services-section-2-card-title-name">
                    Security Fortification
                  </div>
                </div>
                <div className="quality-assurance-services-section-2-card-desc">
                  <ul>
                    <li>
                      Adherence to the highest security standards and best
                      practices.
                    </li>
                    <li>
                      Strategies to enhance visibility, attract traffic, and
                      boost sales.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="container-quality-assurance-design-process">
        <img
          src={isMobile ? QaDesignMobile : QaDesign}
          alt="quality-assurance-design"
        />
      </div>

      <div className="container-latest-work">
        <div className="work-card">
          <LatestWorksPage></LatestWorksPage>
        </div>
      </div>

      <div className="home-lets-get-started">
        <LetsGetStarted></LetsGetStarted>
      </div>

      <div className="quality-assurance-footer mt-20 pb-20 footer-service">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default QualityAssuranceAndTestingService;
