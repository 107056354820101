import React from 'react';
import logo from '../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../styles/footer.css';

const Footer: React.FC = () => {
  return (
    <div className="flex flex-col items-center py-6 w-full">
      <footer className="bg-white p-10 rounded-lg border w-full">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 mb-4 md:mb-0 md:flex md:flex-col">
            <div>
              <img src={logo} alt="Logo" className="logo-image mb-4" />
            </div>
            <p className="address">
              CoHive Green Office Park 9, Jl. BSD Green Office Park,<span className="address-line">Sampora, Kec. Cisauk, Kabupaten Tangerang, Banten</span> <span className="address-line"> 15345</span>
            </p>
            <div className="flex items-center mt-2 contact">
              <FontAwesomeIcon icon={faPhoneAlt} className="icon" />
              <a href="tel:+628111215151">08111215151</a>
            </div>
            <div className="flex items-center mt-2 contact">
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
              <a href="mailto:Lakuuu@gmail.id">Lakuuu@gmail.id</a>
            </div>
          </div>
          <div className="w-full md:w-1/2">
           <h6 className="title">Sitemap</h6>
            <div className="flex flex-wrap gap-4 pt-4 md:pt-0">
              <a href="/ui-ux" className="sitemap flex-basis-auto md:basis-1/5">UI/UX Design</a>
              <a href="/custom-software-development" className="sitemap md:basis-4/6">Custom Software Development</a>
              <a href="/development-service" className="sitemap md:basis-2/7">Web Development</a>
              <a href="/mobile-app-development-service" className="sitemap md:basis-2/5">Mobile App Development</a>
              <a href="/software-integration-service" className="sitemap md:basis-4/7">Software Integration and Migration</a>
              <a href="/ecommerce-solution" className="sitemap md:basis-2/6">E-commerce Solutions</a>
              <a href="/quality-assurance" className="sitemap md:basis-4/7">Quality Assurance and Testing</a>
              <a href="/contact-us" className="sitemap md:basis-2/7">Get in Touch</a>
            </div>
          </div>
        </div>
      </footer>
      <div className="w-full text-center mt-4">
        © 2023 All Right Reserved
      </div>
    </div>
  );
};

export default Footer;