import { useState, ChangeEvent, FormEvent } from "react";
import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/contactUs.css";
import emailIcon from "../assets/email-icon.svg";
import phoneIcon from "../assets/phone-icon.svg";
import axios from "axios";
import MessagePopup from "src/components/MessagePopup";

const ContactUs = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [message, setMessage] = useState("Message sent successfully");
  const [openPopup, setOpenPopup] = useState(false);
  const [popupStatus, setPopupStatus] = useState("");

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setMessage("");

    e.preventDefault();
    const apiUrl = process.env["REACT_APP_API_BASE_URL"];
    try {
      const response = await axios.post(`${apiUrl}/contact-us`, formData);
      if (response) {
        setFormData({ name: "", email: "", phone: "", message: "" });
        setMessage("Success sent message");
        setPopupStatus("success");
        setOpenPopup(true);

        setTimeout(() => {
          setOpenPopup(false);
        }, 2000);
      }
    } catch (error) {
      // Type guard to check if error is an AxiosError
      if (axios.isAxiosError(error)) {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";
        setMessage(errorMessage);
        setOpenPopup(true);

        setTimeout(() => {
          setOpenPopup(false);
        }, 2000);
      } else {
        setMessage("An unexpected error occurred");
        setOpenPopup(true);

        setTimeout(() => {
          setOpenPopup(false);
        }, 2000);
      }
    }
  };

  return (
    <div className="container-contact-us">
      <div className="contact-us-navbar navbar-service">
        <Navbar />
        <MessagePopup
          message={message}
          isOpen={openPopup}
          status={popupStatus}
        ></MessagePopup>
      </div>

      <div className="container-contact-us-form">
        <div className="contact-us-section">
          <div className="title-contact-us">
            <p>Get in Touch</p>
            <p>with Us</p>
          </div>
          <div className="desc mb-8">
            Have questions, need a quote, or want to discuss a project? Feel
            free to reach out to us. Use the form below, and we'll get back to
            you as soon as possible:
          </div>
          <div className="separator-contact-us mb-8" />
          <div className="container-data contact-us-address">
            <p className="contact-us-label">Address</p>
            <p className="content">
              CoHive Green Office Park 9, Jl. BSD Green Office Park, Sampora,
              Kec. Cisauk, Kabupaten Tangerang, Banten 15345
            </p>
          </div>
          <div className="container-data contact-us-email">
            <p className="contact-us-label">Email</p>
            <div className="contact-name">
              <img src={emailIcon} alt="email-icon" />
              <p>Hendrawan@lakuuu.id (Hendrawan)</p>
            </div>
            <div className="contact-name">
              <img src={emailIcon} alt="email-icon" />
              <p>Cynthia@lakuuu.id (Cynthia)</p>
            </div>
          </div>
          <div className="container-data contact-us-phone">
            <p className="contact-us-label">Phone</p>
            <div className="contact-name">
              <img src={phoneIcon} alt="phone-icon" />
              <p>0877 41 300 500 (Hendrawan)</p>
            </div>
            <div className="contact-name">
              <img src={phoneIcon} alt="phone-icon" />
              <p>0818 0681 9905 (Cynthia)</p>
            </div>
          </div>
          {isMobile && <div className="separator-contact-us mb-8 mt-8" />}
        </div>
        <form className="contact-us-form" onSubmit={handleSubmit}>
          <div className="contact-us-form-input input-name">
            <label htmlFor="name">Name</label>
            <input
              placeholder="Enter Your Name"
              type="text"
              id="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="contact-us-form-input input-email">
            <label htmlFor="email">Email</label>
            <input
              placeholder="Enter Your Email"
              type="text"
              id="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="contact-us-form-input input-phone">
            <label htmlFor="phone">Phone</label>
            <div className="container-phone-number-area">
              <div className="phone-number-area">+62</div>
              <div className="phone-number-area-input">
                <input
                  placeholder="No Telephone"
                  type="text"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="contact-us-form-input input-message">
            <label htmlFor="message">Message</label>
            <textarea
              placeholder="Enter Your Message"
              name="message"
              id="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <button className="submit-contact-us-btn mt-8" type="submit">
            Submit
          </button>
        </form>
      </div>

      <div className="contact-us-footer mt-20 pb-20 footer-service">
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
