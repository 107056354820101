
import thumbnail1 from '../assets/custom-software-dev-portfolio-2.png';
import thumbnail2 from '../assets/custom-software-dev-portfolio-1.png';
import Navbar from "../components/Navbar";
import LetsGetStarted from "../components/LetsGetStarted";
import Footer from "../components/Footer";
import "../styles/portfoliopage.css";

const CustomSoftwareDevelopmentServicePortfolioPage = () => {
  return (
    <div className="min-h-screen bg-[#fafafa]">
      <div className="fixed top-0 z-[1000] w-full md:w-4/5 mx-auto left-1/2 transform -translate-x-1/2 px-3 md:px-0">
        <Navbar />
      </div>
      <div className="mx-auto w-full md:w-4/5 pl-2 md:pl-0 pt-40">
        <h1 className="text-55px font-bold mb-4 text-[#2B2A29] p-2  font-poppins">Portal & Supplier Medical System</h1>
        <div className="flex flex-wrap -mx-4 mb-8">
          <div className="w-full md:w-[25%] px-4 mb-4 md:mb-0">
            <h2 className="text-28px font-bold text-[#FD7600] p-2 font-poppins">Client</h2>
            <p className="text-23px text-[#585757] p-2 font-poppins">The Entourage Effect</p>
          </div>
          <div className="w-full md:w-[35%] px-4 mb-4 md:mb-0">
            <h2 className="text-28px font-bold text-[#FD7600] p-2 font-poppins">Service</h2>
            <p className="text-23px text-[#585757] p-2 font-poppins">Custom Software Development</p>
          </div>
          <div className="w-full md:w-[40%] px-4  ">
            <h2 className="text-28px font-bold text-[#FD7600] p-2 font-poppins">About</h2>
            <p className="text-23px text-[#585757] p-2 font-poppins">This project offers custom software development for ecommerce portals and medical systems, catering to the unique needs of clients in these industries.</p>
          </div>
        </div>
        <div className="flex flex-wrap justify-center">
          <div className="w-full lg:w-full px-2 mb-14">
            <img src={thumbnail1} alt="Custom Software Development Thumbnail" className="w-full rounded shadow-lg" />
          </div>
          <div className="w-full lg:w-full px-2 mb-4">
            <img src={thumbnail2} alt="Custom Software Development  Thumbnail" className="w-full rounded shadow-lg" />
          </div>
        </div>
      </div>
      <div className="bg-[#fafafa] w-full md:w-4/5 mx-auto pt-12 mb-12 px-3 md:px-0">
        <LetsGetStarted />
      </div>
      <div className="bg-[#fafafa] w-full md:w-4/5 mx-auto px-3 md:px-0">
        <Footer />
      </div>
    </div>
  );
};

export default CustomSoftwareDevelopmentServicePortfolioPage;